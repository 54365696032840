<template>
  <span>hello</span>
</template>

<script>
    import {useRouter} from 'vue-router'
    export default {
        setup(){
            const router = useRouter()
            router.push({name:'project.index'})
        }
    }
</script>